<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="payment">
          <div class="row">
            <div class="col-12">
              <p class="text-center payment__title">Order status will be shown here</p>
              <br>
                <!-- <orderItem  :order="orderData" /> -->
                <OrderListItem :order="orderData"></OrderListItem>
              <br>
              <div class="payment__user">
                <div class="payment__user-email d-flex">
                  <p style="margin-right: 5px;">Email:</p>
                  <p>{{ this.user.email }}</p>
                </div>
                <div class="d-flex">
                  <p style="margin-right: 5px;">User id:</p>
                  <p>{{ this.user.user_id }}</p>
                </div>
              </div>
              <p>{{ this.orderBelong ? "Order belongs" : "Order doesn't belong" }}</p>
              <br>
              <br>
              <div v-if="!isLoading" class="d-flex justify-content-center">
                <PaymentSuccess v-if="orderData.status === 'payed'"/>
                <PaymentFail v-if="orderData.status === 'fail'"/>
                <PaymnetCancelled v-if="orderData.status === 'new'"/>
              </div>
              <div v-if="isLoading" class="text-center">Loading</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import PaymentSuccess from "@/views/payment-result-components/PaymentSuccess.vue";
import PaymentFail from "@/views/payment-result-components/PaymentFail.vue";
import PaymnetCancelled from "@/views/payment-result-components/PaymnetCancelled.vue";
import {mapGetters} from "vuex";
import OrderListItem from "../components/lists/order-list-item.vue";

export default {
  components: { PaymnetCancelled, PaymentFail, PaymentSuccess, OrderListItem },
  data() {
    return {
      orderId: null,
      orderData: {},
      isLoading: true,
    }
  },
  methods: {
    getOrder() {
      let db = firebase.firestore();
      let id = Number(this.orderId);
      db
        .collection("orders")
        .where("id", "==", id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.orderData = doc.data();
          })
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        })
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    orderBelong()  {
      if (this.orderData && this.user) {
        return this.orderData.user === this.user.uid
      } else {
        return null
      }
    },
  },
  mounted() {
    this.orderId = this.$route.params.orderId
    this.getOrder()
  }
}
</script>

<style scoped lang="scss">
  .payment {
    border-radius: 15px;
    padding: 15px;
    background-color: #16193e;
    color: #fff;
    &__title {
      font-size: 24px;
      color: #fff;
    } 
  }
</style>